@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;900&display=swap');


@layer base {
 

  body {
    font-family: 'Prompt', sans-serif;
  }

.landing * {
  font-family: 'Prompt', sans-serif;
  
}
  .montserrat {
    font-family:'Montserrat', sans-serif;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@layer components {
  .host {
    @apply bg-[#FFFFFF] hr:px-10 desktop:px-9 laptop:px-8 tablet:px-7 small:px-6 p-5 desktop:py-12 border-2 tablet:py-10 small:py-6 hr:max-w-[400px] rounded;
  }
}



.accordion-button:not(.collapsed) {
  color: #485f95;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23485F95'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.scrollbar {
  margin-left: 30px;
  float: left;
  height: 300px;
  width: 65px;
  background: #f5f5f5;
  overflow-y: scroll;
  margin-bottom: 25px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 180px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #686868;
}

html {
  scroll-behavior: smooth;
}

.StepperContainer-0-2-1{
  padding:0 !important;
}
.StepContainer-0-2-2{
  left:-50px
}
.advantages .card, 
.characteristics .card,
.cost .card{
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

 .slick-tracks .slick-list .slick-track{
  display: flex;
  align-items: center;
}
.servises_block {
  position: relative;

}
.servises .servises_other {
  position: absolute;
  top: -160px;
  left: -23px;
  display: none;
  height: 152px;
}
.servises:hover .servises_other {
  display: block;
}

.triangle-top {
  position: absolute;
  bottom: -9px;
  left: 25px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
}
.form-control {
  width: 100%;
}